<template>
  <div class="inMapMain">
    <div class="header">
      <span class="titleIcon">AIPARK 智慧泊车管理平台</span>
      <!-- <div class="titleBg">
        <span class="title">智慧泊车管理平台</span>
      </div> -->
      <img src="./images/titleov.png" class="" style="width: 100%" alt="" />
      <img
        src="./images/choiceov.png"
        class=""
        style="width: 962px; margin-top: -30px; transform: translateY(6px)"
        alt=""
      />
      <div class="choiceovC">
        <div v-for="(item, index) in choiceovCS" :key="index" class="choiceovCItem">
          <!--  :class="index == choiceovSY ? 'ccItemClor ccItem2' : ''" -->
          <span
            :class="getMax(goevaluateindexMap, index) == 1 ? 'ccItemClor ccItem2' : ''"
            class="ccItem"
            @click="secletPark(index)"
            >{{ item }}</span
          >
        </div>
      </div>
      <!-- <img
        src="./images/choiceov.png"
        class=""
        style="width: 962px; margin-top: -30px;transform:translateY(-16px)"
        alt=""
      /> -->
    </div>
    <!-- 地图 -->
    <div class="homeContent">
      <div id="container"></div>
    </div>
    <!-- 搜索 -->
    <div class="parkSearch">
      <el-dropdown
        trigger="click"
        :hide-on-click="true"
        @command="handleCommand"
        placement="bottom-start"
        class="dropdownWrapper"
      >
        <div class="el-dropdown-link parkSearch-menuBar">
          <span class="el-dropdown-link menuBarFs">
            {{ downName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        </div>
        <el-dropdown-menu slot="dropdown" class="dropdownStyle">
          <el-dropdown-item command="0">位置</el-dropdown-item>
          <el-dropdown-item command="1">停车场</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-autocomplete
        popper-class="my-autocomplete"
        v-model="parkName"
        style="margin-top: 6px; margin-left: 10px"
        :fetch-suggestions="querySearchAsync"
        :placeholder="inputPlaceholder"
        :trigger-on-focus="false"
        v-show="!addressshow"
        @select="handleSelect"
      >
        <template slot-scope="{ item }">
          <div class="name" v-if="isParkingLot">{{ item.parkName }}</div>
          <!-- <div class="name" v-else>{{ item.pdaManagerName }}</div>
          <div class="name" v-if="parkNumber">{{ item.plateNumber }}</div> -->
          <!-- <div class="name" v-if="hotValue">{{ item.businessCenterName }}</div> -->
        </template>
      </el-autocomplete>
      <input
        id="pickerInput"
        v-show="addressshow"
        v-model="pickerdata"
        @focus="getfocus"
        autocomplete="off"
        :placeholder="inputPlaceholder"
        style="
          position: absolute;
          z-index: 3;
          left: 68px;
          top: 3px;
          width: 130px;
          height: 24px;
          border: none;
          padding: 0px;
        "
      />
    </div>
    <!--车场详情-->
    <div class="parkDetailWraper" v-show="parkDetailWraper" ref="parkDetailWraper">
      <parkDetail :parkDetailValue="parkDetailValue" :videoList="videoList"></parkDetail>
    </div>
    <!-- 全部车场、路内车场、路外车场 -->
    <!-- <div class="parkCS" v-show="$route.path== '/home'">
      <div
        class=""
        :class="parkCSleParms == index ? 'parkCSItemColor parkCSItem' : 'parkCSItemHover'"
        v-for="(item, index) in parkS"
        :key="index"
        @click="parkCSle(index)"
      >
        {{ item }}
      </div>
    </div> -->
    <!-- 全部车场-->
    <!-- <div class="parkAll" v-show="$route.path== '/home'">
      <img
        :src="bootomLeftImg"
        alt=""
        class="parkAllImg"
        @click="parkSle"
        @mouseover="onOut(1)"
        @mouseout="inOut(1)"
        style="margin-right: 56px;"
      />
      <div class="parkBox">
        <div :style="'transform:' + translateXData" ref="barparent" class="parkBoxMain">
          <span
            class="parkAllItem"
            :class="parkAllItemParms.areaCode == item.areaCode ? 'parkAllItemColor' : ''"
            v-for="(item, index) in parkSAll"
            :key="index"
            @click="parkSleItem(item)"
          >
            {{ item.areaName }}
          </span>
        </div>
      </div>
      <img
        :src="bootomRightImg"
        alt=""
        class="parkAllImg"
        @click="parkSle"
        @mouseover="onOut(2)"
        @mouseout="inOut(2)"
         style="margin-left: 56px;"
      />
    </div> -->
    <div class="fullFigure">
      <h1>剩余泊位</h1>
      <div><span class="fullFigure-one"></span><span>50%以上</span></div>
      <div><span class="fullFigure-two"></span><span>20%~50%</span></div>
      <div><span class="fullFigure-three"></span><span>20%以下</span></div>
    </div>
  </div>
</template>
<script>
import parkDetail from "./componets/summarizePark";
import { getQueryVariable } from "@/common/js/public.js";
export default {
  name: "newHome",
  data() {
    return {
      // 头部筛选
      choiceovCS: ["热力图", "停车场", "事件预警"],
      choiceovSY: "4",
      goevaluateindexMap: [0, 1, 2],
      stop: "0",
      // 大额欠费
      LargePosition: [],
      largeMarkerS: [],
      largetimer: "",
      // 底部筛选
      parkAllItemParms: "0",
      parkCSleParms: "0",
      translateXData: "translateX(0px)",
      parkS: ["全部车场", "路内车场", "路外车场"],
      parkSAll: [],
      bootomLeftImg: require("./images/parkleft.png"),
      bootomRightImg: require("./images/parkright.png"),
      adcode: "110000",
      selAreaID: "",
      // 搜索
      downName: "停车场",
      parkName: "",
      inputPlaceholder: "请输入停车场",
      isParkingLot: true,
      parkNumber: false,
      autoOptions: {
        input: "pickerInput",
      },
      addressshow: false,
      pickerdata: "",
      // 车场详情
      parkDetailValue: {},
      parkDetailWraper: false,
      videoList: [],
      // 地图
      map: "",
      iconMasterN11: require("./images/iconMasterN11.png"),
      iconMasterN22: require("./images/iconMasterN22.png"),
      iconMasterN33: require("./images/iconMasterN33.png"),
      iconMasterY11: require("./images/iconMasterY11.png"),
      iconMasterY22: require("./images/iconMasterY22.png"),
      iconMasterY33: require("./images/iconMasterY33.png"),
      largeImg: require("@/assets/img/car-pic.png"),
      lineImg: require("./images/line.png"),
      districtExplorer: "",
      mars: [],
      // 热力图
      parkNameListAll: [],
      heatmap: "",
      // 定时任务
      timmer: "",
      tagMap: "",
      ItemMap: 999,
      markers: [],
      markersChildren: [],
    };
  },
  components: {
    parkDetail,
  },
  methods: {
    // 头部筛选
    secletPark(secletParms) {
      this.tagMap = secletParms;
      let arrayM = this.goevaluateindexMap; // 存选中的数组
      let falgMap = "";
      this.ItemMap = 999;
      arrayM.filter((value, index) => {
        if (value == this.tagMap) {
          this.ItemMap = value;
          falgMap = index;
        }
      });
      if (this.ItemMap === 999) {
        arrayM.push(this.tagMap);
        // 热力图显示
        if (this.tagMap == 0) {
          this.getHeatMap();
          this.heatmap.show();
        }
        if (this.tagMap == 1) {
          this.map.add(this.mars);
        }
        if (this.tagMap == 2) {
          this.getLarge(1);
        }
      } else {
        arrayM.splice(falgMap, 1);
        if (this.tagMap == 0) {
          this.getHeatMap();
          this.heatmap.hide();
        }
        if (this.tagMap == 1) {
          // this.map.add(this.mars);
          this.mars = this.map.getAllOverlays("marker");
          this.map.remove(this.map.getAllOverlays("marker"));
        }
        if (this.tagMap == 2) {
          this.getLarge(0);
        }
      }
      this.goevaluateindexMap = arrayM;
      console.log("goevaluateindexMap", this.goevaluateindexMap);
      console.log("this.ItemMap-->", this.ItemMap);
    },
    getMax(array, id) {
      var falg = 0;
      for (let i = 0; i < array.length; i++) {
        if (array[i] === id) {
          falg = 1;
        }
      }
      console.log("getMax", falg);
      return falg;
    },
    // 大额欠费
    getLarge(stop) {
      let t = this;
      if (stop) {
        this.timmer = setInterval(() => {
          // console.log("测试");
          t.getLargeInfarce();
        }, 60000);
      } else {
        clearInterval(this.timmer);
        this.timmer = null;
      }
    },
    getLargeMarkers() {
      if (this.LargePosition.length == 0) {
        // 没有大额欠费无需绘制坐标
        return;
      }
      for (let i = 0; i < this.LargePosition.length; i++) {
        let showIcon = this.getParkIcon(this.LargePosition, i);
        const marker = new AMap.Marker({
          map: this.map,
          position: [
            this.LargePosition[i].longitude / 1000000,
            this.LargePosition[i].latitude / 1000000,
          ],
          // icon: showIcon,
          content: this.createInfoWindow(showIcon),
          extData: {
            LargePosition: this.LargePosition[i],
          },
        });
        this.largeMarkerS.push(marker);
      }
      if (this.LargePosition) {
        this.largetimer = setTimeout(() => {
          this.map.remove(this.largeMarkerS);
          this.largeMarkerS = [];
          clearTimeout(this.largetimer);
        }, 3000);
      }
    },
    // 构建点坐标
    createInfoWindow(showIcon) {
      //    <img class= "imgpark" src="${this.largeImg}">
      var info = document.createElement("div");
      info.innerHTML = `
        <div class="large">
            <img class="imgpark" src="${showIcon}">
            <span class="largeimgpark">大额欠费<span>
            <img class="line" src="${this.lineImg}">
        </div>
        `;
      return info;
    },
    // 大额欠费接口
    getLargeInfarce() {
      let t = this;
      let maxTimeStamp = new Date().getTime();
      console.log("maxTimeStamp", maxTimeStamp);
      this.$axios
        .get("/acb/2.0/bigScreenQD/dataAnalysisMap/getBigDebtAlarmList", {
          data: {
            maxTimeStamp: maxTimeStamp,
            queryCnt: "15",
            areaId: this.selAreaID ? this.selAreaID : this.parkAllItemParms.areaId,
          },
        })
        .then((res) => {
          this.LargePosition = res.value;
          t.getLargeMarkers();
        });
    },
    // 底部筛选
    parkCSle(indexParkCSleParms) {
      this.parkCSleParms = indexParkCSleParms;
      this.$store.commit("SET_regionalCollection", {
        areaId: this.parkAllItemParms.areaId,
        dataSource: this.parkCSleParms == 0 ? "" : this.parkCSleParms,
      });
    },
    parkSle() {
      let widthContent = parseInt(this.$refs.barparent.clientWidth);
      let itemContent = "0";
      this.$refs.barparent.childNodes.forEach((item) => {
        itemContent = parseInt(item.clientWidth) + parseInt(itemContent);
      });
      let offsetWidth =
        24 +
        itemContent -
        widthContent +
        this.$refs.barparent.childNodes[this.$refs.barparent.childNodes.length - 1].clientWidth;
      if (itemContent - widthContent > 0) {
        if (this.translateXData == "translateX(0px)") {
          this.translateXData = `translateX(-${offsetWidth}px)`;
        } else {
          this.translateXData = "translateX(0px)";
        }
      } else {
        console.log("不需要移动");
      }
      console.log("itemContent", itemContent);
      console.log("widthContent", widthContent);
    },
    onOut(pam) {
      if (pam == 2) {
        this.bootomRightImg = require("./images/parkright_hover.png");
      }
      if (pam == 1) {
        this.bootomLeftImg = require("./images/parkleft_hover.png");
      }
    },
    inOut(pam) {
      if (pam == 2) {
        this.bootomRightImg = require("./images/parkright.png");
      }
      if (pam == 1) {
        this.bootomLeftImg = require("./images/parkleft.png");
      }
    },
    parkSleItem(indexParkAllItemParms) {
      // 左右全局参数
      console.log("areaId", indexParkAllItemParms.areaId);
      console.log("this.adcode", this.adcode);
      console.log("dataSource", this.parkCSleParms);
      console.log("areaId", indexParkAllItemParms.parkCSleParms);
      this.parkAllItemParms = indexParkAllItemParms;
      this.selAreaID = indexParkAllItemParms.areaId;
      this.adcode = indexParkAllItemParms.areaCode;
      this.$store.commit("SET_regionalCollection", {
        areaId: indexParkAllItemParms.areaId,
        dataSource: this.parkCSleParms == 0 ? "" : this.parkCSleParms,
      });
      this.upDataRegion(this);
      this.getHostParkNew(this.adcode);
      this.parkDetailWraper = false;
    },
    // 地图
    initMap() {
      this.map = new AMap.Map("container", {
        mapStyle: "amap://styles/a2830c4204ddc7ea7c685a99076c6bc3",
        zoom: 13,
        pitch: 65,
        rotation: 0,
        viewMode: "3D",
        center: [116.397428, 39.90923],
      });
      this.districtClusterInitPae1(this);
      this.select(this);
      this.initHeatMapDom();
      // this.getInitMapBar();
    },
    // 小组件
    getInitMapBar() {
      let t = this;
      AMapUI.loadUI(["control/BasicControl"], function (BasicControl) {
        t.map.addControl(
          new BasicControl.Zoom({
            position: {
              bottom: "10%",
              right: "25%",
            }, // left top，左上角
          })
        );
      });
    },
    // 加载搜索组件数据
    select(t) {
      // 构造地点查询类
      AMapUI.loadUI(["misc/PoiPicker"], function (PoiPicker) {
        var poiPicker = new PoiPicker(t.autoOptions);
        // 初始化poiPicker
        t.poiPickerReady(poiPicker);
      });
    },
    // 调用搜索组件方法
    poiPickerReady(poiPicker) {
      let that = this;
      window.poiPicker = poiPicker;
      let marker = new AMap.Marker();
      let infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -20),
      });
      poiPicker.on("poiPicked", function (poiResult) {
        let poi = poiResult.item;
        console.log("item", poiResult);
        marker.setMap(that.map);
        infoWindow.setMap(that.map);

        marker.setPosition(poi.location);
        infoWindow.setPosition(poi.location);

        infoWindow.open(that.map, marker.getPosition());
        let lng = that.map.getCenter().lng;
        let lat = that.map.getCenter().lat;
        let location = [];
        // 高德有部分没有坐标
        if (poi.location) {
          location = [poi.location.lng, poi.location.lat];
        } else {
          location = [lng, lat];
        }
        that.getAmapInit(location);
      });
    },
    // 行政区划
    districtClusterInitPae1(t) {
      AMapUI.load(["ui/geo/DistrictExplorer", "lib/$"], function (DistrictExplorer, $) {
        t.districtExplorer = new DistrictExplorer({
          eventSupport: true, // 打开事件支持
          map: t.map,
        });
        t.map.on("click", function (e) {
          t.upArea(e.lnglat, t);
        });
        t.upDataRegion(t);
      });
    },
    upArea(lnglat, t) {
      t.districtExplorer.locatePosition(
        lnglat,
        function (error, routeFeatures) {
          t.adcode = routeFeatures[routeFeatures.length - 1].properties.adcode;
          console.log("lnglat", lnglat);
          console.log("adcode", t.adcode);
          t.upDataRegion(t);
          // t.getAreaId();
        },
        {
          levelLimit: 4,
        }
      );
    },
    upDataRegion(t) {
      t.districtExplorer.loadAreaNode(t.adcode, function (error, areaNode) {
        if (error) {
          console.error(error);
          return;
        }
        t.renderAreaNode(t.districtExplorer, areaNode);
      });
    },
    renderAreaNode(districtExplorer, areaNode) {
      districtExplorer.clearFeaturePolygons();
      districtExplorer.renderParentFeature(areaNode, {
        cursor: "default",
        bubble: true,
        strokeColor: "#00EBEB",
        fillColor: null,
        strokeWeight: 3,
      });
      this.map.setFitView(districtExplorer.getAllFeaturePolygons());
    },
    getAreaId() {
      let t = this;
      this.$axios
        .get("/acb/2.0/bigScreenQD/area/queryAreaByCode", {
          data: {
            areaCode: this.adcode,
          },
        })
        .then((res) => {
          if (res.value) {
            this.selAreaID = res.value.areaId;
            t.$store.commit("SET_regionalCollection", {
              areaId: res.value.areaId,
              dataSource: this.parkCSleParms == 0 ? "" : this.parkCSleParms,
            });
          } else {
            console.log("测试", "没有code先不提示");
          }
        });
    },
    getAreaCode() {
      let areaId = decodeURIComponent(getQueryVariable("areaId")).replaceAll("'", "");
      console.log("areaId", areaId);
      console.log("window.location", window.location);
      console.log("numberareaIds", areaId.replaceAll("'", ""));
      if (!areaId) {
        this.getArea();
        return;
      }
      this.$axios
        .get("/acb/2.0/bigScreenQD/area/", {
          data: {
            areaId: areaId,
          },
        })
        .then((res) => {
          console.log("/bigScreenQD/area/", res);
          this.getArea(res.value.areaId, res.value.areaCode);
        });
    },
    // 热力图
    initHeatMapDom() {
      let t = this;
      this.map.plugin(["AMap.Heatmap"], function () {
        // 初始化heatmap对象
        t.heatmap = new AMap.Heatmap(t.map, {
          visible: true,
          radius: 50, // 给定半径
          opacity: [0, 0.7],
          gradient: {
            // 0.2: "blue",
            // 0.5: "rgb(0, 255, 0)",
            // 1.0: "red",
            0.2: "rgba(255, 73, 73, 0.14)",
            0.65: "rgba(105, 223, 228, 0.36)",
            0.7: "rgba(91, 200, 145, 1)",
            0.9: "rgba(204, 215, 98, 1)",
            1.0: "rgba(212, 119, 119, 1)",
          },
        });
      });
    },
    // 绘制热力图
    getHeatMap() {
      let points = [];
      this.parkNameListAll.forEach((e) => {
        let point = {
          lng: e.longitude / 1000000,
          lat: e.latitude / 1000000,
          count: (e.occupyAmount / e.amount) * 1000,
        };
        points.push(point);
      });
      this.heatmap.setDataSet({
        data: points,
        max: 500,
      });
    },
    // 搜索
    handleCommand(command) {
      if (command !== 0) {
        this.pickerdata = "";
      }
      if (command == 0) {
        this.downName = "位置";
        this.inputPlaceholder = "请输入位置";
        this.parkDetailWraper = false;
        this.addressshow = true;
      } else if (command == 1) {
        this.downName = "停车场";
        this.inputPlaceholder = "请输入停车场";
        this.addressshow = false;
      }
    },
    querySearchAsync(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      if (this.isParkingLot && this.parkNumber == false) {
        this.$axios
          .get("/acb/2.0/bigScreenQD/systems/loginUser/getParkName", {
            data: {
              page: 1,
              pageSize: 15,
              slaveRelations: "0,1",
              parkName: queryString,
            },
          })
          .then((res) => {
            let arr = res.value.list;
            let newArr = [];
            for (let i = 0; i < arr.length; i++) {
              newArr.push(arr[i]);
            }
            cb(newArr);
          });
      }
    },
    handleSelect(item) {
      if (this.isParkingLot && this.parkNumber !== true) {
        this.getParkInfo(item.parkId, "#parkId" + item.parkId);
      }
    },
    getfocus() {
      this.parkDetailWraper = false;
    },
    // 查询停车场的基本信息
    getParkInfo(parkId, target) {
      console.log("查询车场详情获取1：" + parkId);
      console.log("查询车场详情获取2：" + target);
      let that = this;
      this.$axios
        .get("/acb/2.0/bigScreenQD/map/getParkInfo", {
          data: {
            parkId: parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let item = res.value;
            this.map.setCenter([item.longitude / 1000000, item.latitude / 1000000]);
            this.parkDetailValue = res.value;
            this.parkDetailWraper = true;
            let lnglat = [item.longitude / 1000000, item.latitude / 1000000];
            this.getAmapInit(lnglat);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 根据坐标获取地理位置编码
    getAmapInit(lnglat) {
      let that = this;
      AMap.plugin("AMap.Geocoder", function () {
        let geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: "010",
        });
        geocoder.getAddress(lnglat, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // result为对应的地理位置详细信息
            console.log(
              "详情拿到的地址和编码:" +
                result.regeocode.addressComponent.province +
                result.regeocode.addressComponent.adcode
            );
            that.getHostParkNew(result.regeocode.addressComponent.adcode, 1);
          }
        });
      });
    },
    // 地图查询所停车场 target点击车场坐标时不清除车场坐标
    // bigScreenQD/map/allParkByAreaCode
    getHostParkNew(adcode, target) {
      let markers = [];
      this.$axios
        .get("/acb/2.0/bigScreenQD/map/allParkByAreaCode", {
          data: {
            areaCode: adcode,
          },
        })
        .then((res) => {
          if (target != 1) {
            this.map.remove(this.map.getAllOverlays("marker"));
          }
          let basePosition = res.value;
          // let basePosition = this.LargePosition;
          this.parkNameListAll = res.value;
          let markersArr = this.markersChildren;
          if (target != 1 && this.ItemMap == 999) {
            this.getHeatMap();
            this.heatmap.show();
            console.log("绘制热力图this.ItemMap->parkNameListAll", this.parkNameListAll);
          }
          // for (let i = 0; i < basePosition.length; i++) {
          //   const marker = new AMap.Marker({
          //     map: this.map,
          //     position: [
          //       basePosition[i].longitude / 1000000,
          //       basePosition[i].latitude / 1000000,
          //     ],
          //     // icon: this.getParkIcon(basePosition, i),
          //     content: `<div id="parkId${basePosition[i].parkId
          //         }" class="iconDefault" style="background: url('${this.getParkIcon(basePosition, i)}') no-repeat center/ cover;"></div>`,
          //     extData: {
          //       basePosition: basePosition[i],
          //     },
          //   });
          //   // marker逐一push到markers
          //   this.markers.push(marker)
          //   // 添加点击事件
          //   marker.on("click", (e) => {
          //     let parkCllckId = e.target.getExtData().basePosition.parkId;
          //     this.getParkInfo(parkCllckId, "#parkId" + parkCllckId);
          //     this.scaleDiv("#parkId" + parkCllckId);
          //   });
          // }

          // 遍历往地图添加标点
          let forNumber = 0;
          for (let i = 0; i < basePosition.length; i++) {
            forNumber++;
            // 循环遍历新请求的数组跟现在的是否有重复
            let forEsc = false;
            for (let j = 0; j < markersArr.length; j++) {
              if (basePosition[i].parkId == markersArr[j].parkId) {
                forEsc = true;
                break;
              }
            }
            if (!forEsc) {
              console.log("123231231");
              const marker = new AMap.Marker({
                map: this.map,
                position: [basePosition[i].longitude / 1000000, basePosition[i].latitude / 1000000],
                // icon: preIcon,
                content: `<div id="parkId${
                  basePosition[i].parkId
                }" class="iconDefault" style="background: url('${this.getParkIcon(
                  basePosition,
                  i
                )}') no-repeat center/ cover;"></div>`,
                extData: {
                  basePosition: basePosition[i],
                  index: i,
                },
              });
              this.markers.push(marker);
              this.markersChildren.push({
                parkId: basePosition[i].parkId,
              });
              // 添加点击事件
              marker.on("click", (e) => {
                let parkCllckId = e.target.getExtData().basePosition.parkId;
                this.getParkInfo(parkCllckId, "#parkId" + parkCllckId);
                this.scaleDiv("#parkId" + parkCllckId);
              });
            }
          }
        });
    },
    scaleDiv(target) {
      setTimeout(() => {
        // let ele = document.querySelector(`#wrapper_content ${target}`);
        let ele = document.querySelector(`${target}`);
        if (ele) {
          ele.className = "activeIcon";
        }
        if (window.activeParkIcon && ele != window.activeParkIcon) {
          window.activeParkIcon.className = "iconDefault";
        }
        window.activeParkIcon = ele;
      }, 500);
    },
    getParkIcon(basePosition, i) {
      // console.log("测试", basePosition);
      let occupy = 100 - (basePosition[i].occupyAmount / basePosition[i].amount) * 100;
      let showIcon;
      if (occupy > 50) {
        showIcon = `${basePosition[i].dataSource === 1 ? this.iconMasterY11 : this.iconMasterN11}`;
      } else if (occupy >= 20) {
        showIcon = `${basePosition[i].dataSource === 1 ? this.iconMasterY33 : this.iconMasterN33}`;
      } else {
        showIcon = `${basePosition[i].dataSource === 1 ? this.iconMasterY22 : this.iconMasterN22}`;
      }
      return showIcon;
    },
    // 初始化用户区域下拉列表
    getArea(areaId, parms) {
      // let  parms = getQueryVariable("regionCode")
      // let  parms = getQueryVariable("areaCode")
      let data = {
        areaId: Number(areaId) ? Number(areaId) : 281479271940097,
        areaName: "北京市",
        areaCode: parms ? Number(parms) : 110000,
        parentId: "281479271677952",
        depth: 3,
        latitude: 39904179,
        longitude: 116407387,
        areaType: 0,
        realAreaCode: "",
      };
      console.log("data", data);
      this.parkAllItemParms = data;
      this.adcode = data.areaCode || "110000";
      setTimeout(() => {
        this.initMap();
        if (this.ItemMap == 999) {
          this.getLarge(1);
        }
        this.getHostParkNew(this.adcode);
      }, 500);
    },
  },
  created() {
    // this.getLargeInfarce();
    // this.getArea();
    console.log("route", this.$route.path);
  },
  watch: {
    // pickerdata: {
    //   handler(newVal) {
    //      if (newVal.length > 0) {
    //        this.parkDetailWraper = false;
    //      }
    //   }
    // },
  },
  mounted() {
    this.$nextTick(() => {
      this.getAreaCode();
    });
  },
  beforeDestroy() {
    clearInterval(this.timmer);
    this.timmer = null;
  },
};
</script>

<style scoped lang="stylus">
.homeContent {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
  .titleBg{
    background: url("../../assets/img/home_top_logo.png") no-repeat;
    background-position: 0 center;
    padding-left: 120px;
    margin: 0 auto;
    width: 192px;
    text-align :center;
  }
    .title{
       height:40px;
      line-height: 40px;
      font-size: 24px;
      background: linear-gradient(180deg, #afecff, #4edbff);
      -webkit-background-clip: text;
      color: transparent;
      margin: 0 auto;
      display: inline-block;
    }

#container {
  width: 100%;
  height: 100%;
}

.header {
  position: absolute;
  top: 0px;
  z-index: 999;
  text-align: center;
}

.header img {
  margin-top: -42px;
}

.titleIcon {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  padding-top: 6px;
  display: inline-block;
  padding-bottom: 6px;
}
@media screen and (max-width 1680px) {
  .titleIcon {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    padding-top: 6px;
    display: inline-block;
    padding-bottom: 6px;
   }
}
.choiceovC {
  width: 250px;
  margin: 0 auto;
  display: flex;
  transform: translateY(-20px);
}

.choiceovCItem {
  flex: 1;
  height: 20px;
}

.ccItem {
  position: relative;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #89CBE8;
  cursor: pointer;
}

.ccItem:after {
  content: ' ';
  position: absolute;
  left: -20px;
  top: 36%;
  width: 8px;
  height: 8px;
  background-color: #89CBE8;
  border-radius: 4px;
}

.ccItemClor {
  color: #49B2FF;
}

.ccItem2:after {
  content: ' ';
  position: absolute;
  left: -20px;
  top: 40%;
  width: 8px;
  height: 8px;
  background-color: #49B2FF;
  border-radius: 4px;
}

.parkDetailWraper {
  color: #D8D8D8;
  width: 296px;
  padding: 10px 16px;
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  left: calc(25% + 5px);
  top: 125px;
  font-size: 16px;
  background: linear-gradient(180deg, rgba(182, 246, 255, 0.16) 0%, rgba(132, 173, 255, 0.19) 100%);
  box-shadow: inset 0px 0px 4px 0px rgba(143, 248, 255, 0.76);
  border-radius: 4px;
  backdrop-filter: blur(5px);
}

#container /deep/ {
  .activeIcon {
    width: 25px;
    height: 32px;
  }
  .iconDefault {
    width: 20px;
    height: 26px;
  }
}

// 全部车场 路内车场 路外车场
.parkCS {
  position: absolute;
  bottom: 62px;
  left: calc(48% - 122px);
  z-index: 99;
  display: flex;
}

.parkCSItem {
  width: 102px;
  height: 32px;
  background: url('./images/parkIcon.png') no-repeat center;
  line-height: 32px;
  text-align: center;
  background-size: 100% 100%;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #69B7CC;
  margin-left: 6px;
  cursor: pointer;
}
.parkCSItemHover {
  width: 102px;
  height: 32px;
  background: url('./images/parkIconHover.png') no-repeat center;
  line-height: 32px;
  text-align: center;
  background-size: 100% 100%;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #69B7CC;
  margin-left: 6px;
  cursor: pointer;
}

.parkCSItemColor {
  color: #65FFFF;
}

// 全部车场
.parkAll {
  width: 50%;
  position: absolute;
  bottom: 20px;
  left: 25%;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(182, 214, 255, 0.1) 0%, rgba(132, 173, 255, 0.19) 100%);
  border-radius: 2px;
  backdrop-filter: blur(5px);
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}

.parkBox {
  // max-width: 852px;
  overflow-x: hidden;
  // overflow-x: auto;
  white-space: nowrap;
  // line-height: 32px;
}
.parkBoxMain{
  // background-color: blue;
}
.parkAllItem {
  padding: 10px 8px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  // margin-left: 6px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #78B5FF;
  cursor: pointer;
  display: inline-block;
  // height: 32px;
}

.parkAllItemColor {
  color: #00EBEB;
}

.parkAllImg {
  width: 24px;
  height: 24px;
  padding-top: 5px;
}

.homeContent >>>.large{
  position: relative
  width: 100px;
  .imgpark{
    width: 20px;
    height: 24px;
  }
  .line {
    width: 10px;
    height: 8px;
    position: absolute;
    left: -11px;
    bottom: 0;
  }
  .largeimgpark{
    position: absolute;
    top: -18px;
    left: 30px;
    padding: 5px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 158, 94, 1);
    background: rgba(255, 115, 0, 0.4)
    // background: linear-gradient(180deg, rgba(182,214,255,0.1) 0%, rgba(132,173,255,0.19) 100%);
    border-radius: 4px
  }
}
// 剩余车场
.fullFigure {
  width: 100px;
  height: 118px;
  background: linear-gradient(180deg, rgba(182, 246, 255, 0.16) 0%, rgba(132, 173, 255, 0.19) 100%);
  box-shadow: inset 0px 0px 1px 0px rgba(143, 248, 255, 0.44);
  border-radius: 4px;
  backdrop-filter: blur(5px)
  position: absolute;
  top: 8%;
  right: calc(26% - 10px);
  display: block;
  padding-left 12px;
  padding-top 12px;
  z-index: 999;
  div {
    transform scale(0.96)
    margin: 10px 0;
    font-size 12px;
    height: 20px;
    line-height: 20px;
    span {
      font-size: 12px;
      color: #fff;
    }
  }
  h1{
    color: #fff;
    font-size 14px;
  }
  .fullFigure-one {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #1FFFD3;
    border-radius: 2px;
    margin-right 5px;
    margin-bottom: -3px;
  }
  .fullFigure-two {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #FFA62A;
    border-radius: 2px;
    margin-right 5px;
    margin-bottom: -3px;
  }
  .fullFigure-three {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #FE5151;
    border-radius: 2px;
    margin-right 5px;
    margin-bottom: -3px;
  }
}
</style>
// 搜索
<style lang="stylus">
#pickerInput::-webkit-input-placeholder {
  color: #D8D8D8;
}

.inMapMain .parkSearch {
  position: absolute;
  z-index: 2;
  left: calc(25% + 5px);
  top: 82px;
  width: 296px;
  height: 32px;
  border-radius: 4px;
  padding-left: 40px;
  box-shadow: inset 0px 0px 4px 0px rgba(143, 248, 255, 0.76);
  background: url('./images/selectov.png') no-repeat 268px center;
  backdrop-filter: blur(5px);
  box-sizing: border-box;
  input {
    display: inline-block;
    background: none;
    border: none;
    padding: 0;
    height: 38px;
    color: #D8D8D8;
    opacity: 1;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    padding-left: 40px;
    padding-bottom: 18px;
  }

  .parkSearch-menuBar {
    width: 110px;
    font-family: MicrosoftYaHei;
    color: #F0F0F0;
  }

  .menuBarFs {
    font-size: 14px;
  }
}

.my-autocomplete {
  width: 252px !important;
  margin-left: -40px;
  border: 0px;
  background: linear-gradient(180deg, rgba(182, 246, 255, 0.16) 0%, rgba(132, 173, 255, 0.19) 100%) !important;
  box-shadow: inset 0px 0px 4px 0px rgba(143, 248, 255, 0.76);
  border-radius: 4px;
  backdrop-filter: blur(5px);

  .el-autocomplete-suggestion__wrap {
    max-height: 269px;
    border: none;
    width: 271px;
    overflow-y: scroll !important;

    li:hover {
      background: rgba(67, 101, 242, 0.3) !important;
    }

    li {
      background: url('./images/li_bg.png') no-repeat 22px center;
      padding-left: 50px;
      line-height: 30px;

      .name {
        color: #fff;
      }
    }
  }

  .popper__arrow {
    border-bottom-color: rgba(67, 101, 242, 0.3) !important;
  }

  .popper__arrow::after {
    border-bottom-color: #000d3f !important;
  }
}

.dropdownWrapper {
  width: 40px;
  height: 38px;
  left: 8px;
  top: 29%;
  position: absolute;
}

.dropdownStyle {
  box-shadow: inset 0px 0px 4px 0px rgba(143, 248, 255, 0.76);
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(182, 246, 255, 0.16) 0%, rgba(132, 173, 255, 0.19) 100%);
  border: none;
}

.dropdownStyle li {
  color: #fff;
}

.dropdownStyle .el-dropdown-menu__item:focus, .dropdownStyle .el-dropdown-menu__item:not(.is-disabled):hover {
  background: rgba(67, 101, 242, 0.3);
  color: #ffffff;
}

.dropdownStyle .popper__arrow {
  border-bottom-color: #002F63 !important;
}

.dropdownStyle .popper__arrow::after {
  border-bottom-color: #002F63 !important;
}

.el-popper[x-placement^=bottom] {
  margin-top: -6px;
  margin-left: -7px;
}
</style>
